import { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./faq.css";

function Faq() {
  const [clicked1, setClicked1] = useState(false);
  const [clicked2, setClicked2] = useState(false);
  const [clicked3, setClicked3] = useState(false);
  const [clicked4, setClicked4] = useState(false);
  const [clicked5, setClicked5] = useState(false);
  const [clicked6, setClicked6] = useState(false);
  const [clicked7, setClicked7] = useState(false);
  const [clicked8, setClicked8] = useState(false);
  const [clicked9, setClicked9] = useState(false);
  const [clicked10, setClicked10] = useState(false);
  const [clicked11, setClicked11] = useState(false);
  const [clicked12, setClicked12] = useState(false);
  const [clicked13, setClicked13] = useState(false);
  const [clicked14, setClicked14] = useState(false);
  const [clicked15, setClicked15] = useState(false);

  return (
    <>
      <Header />
      <div className="faq">
        <section className="faq__section__one">
          <h3>Frequently asked question</h3>
          <h1>OXXO University questions guide</h1>
        </section>
        <section className="faq__section__two">
          <p>
            Whether you are a prospective student, current student, parent, or
            community member, we understand that you may have questions about
            our university. This page is designed to provide quick and easy
            access to the information you need about OXXO University. Here, you
            will find answers to the most commonly asked questions about
            admissions, academic programs, campus life, financial aid, and much
            more.
          </p>
          <p>
            At OXXO University, we are committed to providing a supportive and
            enriching environment for all our students. Our diverse community,
            state-of-the-art facilities, and dedicated faculty and staff are
            here to help you succeed academically and personally. If you cannot
            find the answer to your question here, please do not hesitate to
            contact us directly. We are here to assist you every step of the
            way.
            <br />
            Explore our FAQs below to learn more about what makes OXXO
            University a unique and vibrant place to learn and grow.
          </p>
          <h1>General Information</h1>

          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked1(!clicked1);
              }}
            >
              <p>What is OXXO University?</p>
              <MdKeyboardArrowDown
                className={
                  clicked1
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked1
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                OXXO University is an institution dedicated to fostering
                innovation, leadership, and entrepreneurial spirit. We provide a
                transformative educational experience that equips individuals
                with the knowledge, skills, and confidence needed to succeed in
                today's dynamic business landscape.
              </p>
            </div>
          </div>
          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked2(!clicked2);
              }}
            >
              <p>Where is OXXO University located?</p>
              <MdKeyboardArrowDown
                className={
                  clicked2
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked2
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                Our corporate office is located at 52B Tony Eromosele Street,
                Park-View Estate off Gerrard Road, Ikoyi, Lagos State.
              </p>
            </div>
          </div>
          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked3(!clicked3);
              }}
            >
              <p>What programs does OXXO University offer?</p>
              <MdKeyboardArrowDown
                className={
                  clicked3
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked3
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                We offer various MBA programs, including Business Management &
                Entrepreneurship, Supply Chain Management, Retail Management,
                and Fashion Design, Trends & Management. Each program includes
                core modules and an optional mentorship program.
              </p>
            </div>
          </div>
          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked4(!clicked4);
              }}
            >
              <p>What are the core values of OXXO University?</p>
              <MdKeyboardArrowDown
                className={
                  clicked4
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked4
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                Our core values include fostering creativity and
                forward-thinking, promoting integrity, and striving for
                excellence in all endeavors.
              </p>
            </div>
          </div>

          <h1>Admissions</h1>

          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked5(!clicked5);
              }}
            >
              <p>What are the admission requirements for OXXO University?</p>
              <MdKeyboardArrowDown
                className={
                  clicked5
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked5
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                The requirements include business registration documents, a
                personal statement, a valid government-issued ID, and basic
                proficiency in English. Formal education is not strictly
                required, but relevant certificates or training will be
                considered. An online interview may also be required.
              </p>
            </div>
          </div>
          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked6(!clicked6);
              }}
            >
              <p>How do I apply to OXXO University?</p>
              <MdKeyboardArrowDown
                className={
                  clicked6
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked6
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                To apply, complete and submit the application form along with
                the required documents. The admissions committee will review the
                materials, and some applicants may be invited for an interview.
                Successful candidates will be notified via email.
              </p>
            </div>
          </div>
          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked7(!clicked7);
              }}
            >
              <p>Is there an application fee?</p>
              <MdKeyboardArrowDown
                className={
                  clicked7
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked7
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                Please visit our website or contact the admissions office for
                more details.
              </p>
            </div>
          </div>

          <h1>Programs and Curriculum</h1>

          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked8(!clicked8);
              }}
            >
              <p> What is the duration of the MBA programs?</p>
              <MdKeyboardArrowDown
                className={
                  clicked8
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked8
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                Each MBA program lasts for five months, with an optional
                additional month for a mentorship program.
              </p>
            </div>
          </div>
          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked9(!clicked9);
              }}
            >
              <p>
                What is included in the mentorship program? What happens in case
                of damages?
              </p>
              <MdKeyboardArrowDown
                className={
                  clicked9
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked9
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                The optional mentorship program provides personalized guidance
                and industry insights from experienced professionals, helping
                students refine their business ideas and strategies.
              </p>
            </div>
          </div>
          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked10(!clicked10);
              }}
            >
              <p>Are the courses available online?</p>
              <MdKeyboardArrowDown
                className={
                  clicked10
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked10
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                Yes, OXXO University offers flexible learning options, including
                online, in-person, and hybrid courses to accommodate students'
                varying schedules and commitments.
              </p>
            </div>
          </div>
          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked12(!clicked12);
              }}
            >
              <p>
                What kind of learning approach does OXXO University emphasize?
              </p>
              <MdKeyboardArrowDown
                className={
                  clicked12
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked12
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                We emphasize hands-on learning experiences through real-world
                projects, internships, and interactive workshops, allowing
                students to apply their knowledge in practical settings and
                build a robust portfolio of work.
              </p>
            </div>
          </div>

          <h1>Support and Resources</h1>

          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked13(!clicked13);
              }}
            >
              <p>What support services are available to students?</p>
              <MdKeyboardArrowDown
                className={
                  clicked13
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked13
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                We provide comprehensive support services, including academic
                advising, career counseling, and mentorship programs to help
                students achieve their goals and succeed in their careers.
              </p>
            </div>
          </div>
          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked14(!clicked14);
              }}
            >
              <p>What networking opportunities are available?</p>
              <MdKeyboardArrowDown
                className={
                  clicked14
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked14
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                Our programs offer numerous opportunities for students to
                connect with peers, mentors, and industry professionals through
                networking events, guest lectures, and collaborative projects.
                Our extensive alumni network also provides ongoing support and
                opportunities for professional growth.
              </p>
            </div>
          </div>

          <h1>Contact Information</h1>

          <div className="faq__section__two__faq">
            <div
              className="faq__section__two__faq__ques"
              onClick={() => {
                setClicked15(!clicked15);
              }}
            >
              <p>What support services are available to students?</p>
              <MdKeyboardArrowDown
                className={
                  clicked15
                    ? "faq__section__two__faq__icon clicked"
                    : "faq__section__two__faq__icon"
                }
              />
            </div>
            <div
              className={
                clicked15
                  ? "faq__section__two__faq__ans"
                  : "faq__section__two__faq__ans clicked"
              }
            >
              <p>
                You can visit our website at www.oxxouniversity.com or email us
                at help@oxxouniversity.com. For direct inquiries, our corporate
                office is located at 52B Tony Eromosele Street, Park-View Estate
                off Gerrard Road, Ikoyi, Lagos State.
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Faq;
