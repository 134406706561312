import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./contact.css";

function Contact() {
  return (
    <>
      <Header />
      <div className="contact">
        <section className="contact__section__one">
          <h1>Contact us</h1>
        </section>
        <section className="contact__section__two">
          <h1>OXXO University mini MBA Admissions Office – Contact</h1>
          <p>The OXXO University</p>
          <p>10 Abble Joyce Street, Kubwa</p>
          <p> Abuja, FCT</p>
          <span>
            <p>Phone:</p>
            <p>+2348099281429</p>
          </span>
          <span>
            <p>Fax:</p>
            <p>923389829</p>
          </span>
          <span>
            <p>Email:</p>
            <p>help@oxxouniversity.com</p>
          </span>
        </section>
        <section className="contact__section__three">
          <h1>Get in touch with OXXO admissions</h1>
          <button
            onClick={() =>
              (window.location =
                "mailto:help@oxxouniversity.com?subject=Contact OXXO University")
            }
          >
            Send us an email
          </button>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
