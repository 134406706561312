import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { BiSolidContact } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import "./Footer.css";
import oxxologoblack from "../../assets/OXXO LOGO 1 PNG.png";
import { BsArrowUp } from "react-icons/bs";

function Footer() {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="footer">
      <section className="footer__section__zero" onClick={scrollToTop}>
        <h1>
          BACK TO TOP <BsArrowUp />
        </h1>
      </section>
      <section className="footer__section__one">
        <img src={oxxologoblack} alt="" />
        <div>
          <span>
            <FaLocationDot className="footer__section__one__icon" />
            10 Abble Joyce Street, Kubwa, Abuja, FCT
          </span>
          <span>
            <IoMdMail className="footer__section__one__icon" />
            P.O. Box 2197, Kubwa, FCT
          </span>
        </div>
        <div>
          <span>
            <BiSolidContact className="footer__section__one__icon" />
            Contact Us
          </span>
          <span>
            <FaPhone className="footer__section__one__icon" />
            +2348099281429
          </span>
        </div>
      </section>
      <section className="footer__section__two">
        <div>
          <h1>Admission</h1>
          <hr />
          <p
            onClick={() => {
              navigate("/apply");
            }}
          >
            Apply
          </p>
          <p
            onClick={() => {
              navigate("/courses");
            }}
          >
            Courses
          </p>
          <p
            onClick={() => {
              navigate("/");
            }}
          >
            Campuses
          </p>
        </div>
        <div>
          <h1>About us</h1>
          <hr />
          <p
            onClick={() => {
              navigate("/faq");
            }}
          >
            FAQ
          </p>
          <p
            onClick={() => {
              navigate("/");
            }}
          >
            Release
          </p>
        </div>
        <div>
          <h1>Contact</h1>
          <hr />
          <p
            onClick={() => {
              navigate("/contact");
            }}
          >
            Get in touch
          </p>
        </div>
      </section>
      <section className="footer__section__three">
        <p>&copy; Oxxo University Site map | Privacy and terms | Legal</p>
      </section>
    </div>
  );
}

export default Footer;
