import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { FaLinkedinIn } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import { BiSolidContact } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import oxxologowhite from "../../assets/OXXO LOGO 2 PNG.png";
import "./Header.css";

function Header() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const sectionOne = useRef();
  const header = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth > 768) {
        // Check if the viewport is wider than 768px
        const position = window.pageYOffset;
        setScrollPosition(position);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth > 768) {
      // Apply the effect only if the viewport is wider than 768px
      const element = sectionOne.current;
      const element2 = header.current;
      if (scrollPosition > 0) {
        element.style.display = "none";
        element2.style.position = "fixed";
      } else {
        element.style.display = "flex";
      }
    }
  }, [scrollPosition]);

  return (
    <div className="header" ref={header}>
      <section className="header__section__one" ref={sectionOne}>
        <div className="header__section__one__info">
          <p href="tel:+2347016000164">
            +2348099281429
            <FaPhone className="header__section__one__info__icon" />
          </p>
          <p
            onClick={() =>
              (window.location =
                "mailto:johnharry@gmail.com?subject=Access request")
            }
          >
            help@oxxounniversity.com
            <IoMdMail className="header__section__one__info__icon" />
          </p>
          <p>News and media</p>
        </div>
        <div className="header__section__one__socials">
          <p>en | </p>
          <FaFacebookF className="header__section__one__socials__icons" />
          <GrInstagram className="header__section__one__socials__icons" />
          <FaLinkedinIn className="header__section__one__socials__icons" />
          <BsTwitterX className="header__section__one__socials__icons" />
        </div>
      </section>

      <section className="header__section__two">
        <img
          src={oxxologowhite}
          alt=""
          onClick={() => {
            navigate("/");
          }}
        />

        <ul className="header__section__navbar">
          <li
            onClick={() => {
              navigate("/faq");
            }}
          >
            FAQ
          </li>
          <li
            onClick={() => {
              navigate("/courses");
            }}
          >
            Courses
          </li>
          <li
            onClick={() => {
              navigate("/apply");
            }}
          >
            Apply
          </li>
          <li
            onClick={() => {
              navigate("/contact");
            }}
          >
            Contact
          </li>
        </ul>
        <FiMenu
          className="header__section__one__socials__icons"
          onClick={() => {
            setMenu(true);
          }}
        />
      </section>
      <div className={menu ? "home_sidebar_true" : "home_sidebar"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#cc66cc",
            padding: "10px 10px",
          }}
        >
          <Link to="/">
            <img
              src={oxxologowhite}
              className="evajay_header2"
              alt=""
              onClick={() => {
                navigate("/");
              }}
            />
          </Link>
          <AiOutlineClose
            className="home_sidebar_close"
            onClick={() => {
              setMenu(false);
            }}
          />
        </div>
        <ul className="home_sidebar_navbar">
          <li>
            <Link
              onClick={() => {
                setMenu(false);
              }}
              to="/faq"
              className="home_sidebar_link"
            >
              FAQ
            </Link>
          </li>

          <li>
            <Link
              to="/courses"
              className="home_sidebar_link"
              onClick={() => {
                setMenu(false);
              }}
            >
              Courses
            </Link>
          </li>
          <li>
            <Link
              to="/apply"
              className="home_sidebar_link"
              onClick={() => {
                setMenu(false);
              }}
            >
              Apply
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="home_sidebar_link"
              onClick={() => {
                setMenu(false);
              }}
            >
              Contact
            </Link>
          </li>
          <li id="headerlist__section__one">
            <div>
              <span>
                <FaLocationDot id="headerlist__section__one__icon" /> 10 Abble
                Joyce Street, Kubwa
              </span>
              <span>
                <IoMdMail id="headerlist__section__one__icon" /> P.O. Box 2197,
                Kubwa
              </span>
            </div>
            <div>
              <span>
                <BiSolidContact id="headerlist__section__one__icon" /> Contact
                Us
              </span>
              <span>
                <FaPhone id="headerlist__section__one__icon" /> +2348099281429
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
