import { BiArrowBack } from "react-icons/bi";
import { HiMinus } from "react-icons/hi";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./Home.css";
import { IoArrowForward } from "react-icons/io5";
import Meta from "../../utils/Meta";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa6";
import studentImage from "../../assets/group-five-african-college-students-spending-time-together-campus-university-yard-black-afro-friends-studying-bench-with-school-items-laptops-notebooks (1).webp";
import homeFacultyImage1 from "../../assets/young-man-dressed-formal-suit-sitting-cafe.webp";
import homeFacultyImage2 from "../../assets/annie-spratt-_dAnK9GJvdY-unsplash.webp";
import homeCampusImage3 from "../../assets/olawale-munna-qpyR0WKV_Cs-unsplash.webp";
import homeHeroImage1 from "../../assets/robert-gareth-iC-kqcm_feE-unsplash.webp";
import homeHeroImage2 from "../../assets/library-with-books.webp";
import homeHeroImage3 from "../../assets/handsome-man-spending-time-outdoors-using-modern-smartphone.webp";
import { useEffect, useRef, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const homeBlock = useRef();
  const homeBlock2 = useRef();
  const homeBlock3 = useRef();
  const [startIndex, setStartIndex] = useState(0);

  const highlightHomeBlock = () => {
    const element = homeBlock.current;
    if (element.style.textDecoration === "underline") {
      element.style.textDecoration = "none";
    } else {
      element.style.textDecoration = "underline";
    }
  };

  const highlightHomeBlock2 = () => {
    const element = homeBlock2.current;
    if (element.style.textDecoration === "underline") {
      element.style.textDecoration = "none";
    } else {
      element.style.textDecoration = "underline";
    }
  };

  const highlightHomeBlock3 = () => {
    const element = homeBlock3.current;
    if (element.style.textDecoration === "underline") {
      element.style.textDecoration = "none";
    } else {
      element.style.textDecoration = "underline";
    }
  };

  const images = [
    {
      src: homeHeroImage1,
      text: "Learn, grow and connect in a beautiful enviroment that enhance productivity and lifestyle",
    },
    {
      src: homeHeroImage2,
      text: "Get access to amazing catalogue of books and journals, enjoying a wealth of treasures from our archives",
    },
    {
      src: homeHeroImage3,
      text: "One year of learning, A lifetime of growth!",
    },
  ];

  /**
   * Moves the image slider to the specified direction
   * @param {String} direction - The direction to move the image slider to
   */
  const handleArrowClick = (direction) => {
    const maxIndex = images.length - 1;

    // Calculate new start index based on direction
    const newStartIndex =
      direction === "forward" ? startIndex + 1 : startIndex - 1;

    // Wrap around the index
    if (newStartIndex > maxIndex) {
      setStartIndex(0); // If it goes past the last index, reset to 0
    } else if (newStartIndex < 0) {
      setStartIndex(maxIndex); // If it goes before the first index, set to maxIndex
    } else {
      setStartIndex(newStartIndex); // Otherwise, set to the calculated new index
    }
  };

  return (
    <>
      <Meta title="OXXO - Home" />
      <Header />
      <div className="home">
        <section className="home__section__one">
          <div className="home__section__one__start">
            <h3>Your road to greatness starts here</h3>
            <h1>
              We cultivate leaders who create a meaningful impact globally.
            </h1>
            <span onClick={() => navigate("/apply")}>
              Apply for the November cohort <BsArrowRight />
            </span>
          </div>
          {images.slice(startIndex, startIndex + 1).map((item, index) => {
            const bgStyle = {
              backgroundImage: `url(${item.src})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            };
            return (
              <div
                key={index}
                className="home__section__one__end"
                style={bgStyle}
              >
                <span className="home__section__one__end__span">
                  <div>
                    <p>{item.text}</p>
                  </div>
                  <span>
                    <button className="home__section__one__end__span__button">
                      <BiArrowBack
                        onClick={() => handleArrowClick("forward")}
                      />
                    </button>
                    <button className="home__section__one__end__span__button">
                      <IoArrowForward
                        onClick={() => handleArrowClick("backward")}
                      />
                    </button>
                  </span>
                </span>
              </div>
            );
          })}
        </section>
        <section className="home__section__two">
          <h1>Why choose OXXO mini-MBA</h1>
          <div className="home__section__two__wrap">
            <div className="home__section__two__block">
              <h1>Innovative Curriculum</h1>
              <p>
                At OXXO University, we offer an innovative curriculum designed
                to meet the demands of today's rapidly evolving business
                landscape.
              </p>
            </div>
            <div className="home__section__two__block">
              <h1>Expert Faculty </h1>
              <p>
                Our faculty comprises experienced industry leaders, succesful
                entrepreneurs, and seasoned professionals who bring wealth of
                knowledge and practical experience to th classroom
              </p>
            </div>
            <div className="home__section__two__block">
              <h1>Hands-on Learning </h1>
              <p>
                We believe that the best way to learn is by doing. That's why
                OXXO University emphasizes hands on lerning experiences through
                real-world projects, internships and interactive workshops.
              </p>
            </div>
            <div className="home__section__two__block">
              <h1>Networking Opportunities </h1>
              <p>
                At OXXO University we understand th importance of networking in
                building a succesfulcareer. Our programs offer numerous
                opportunities for students to connect with peers, mentors, and
                industry professionals.
              </p>
            </div>
          </div>
        </section>
        <section className="home__section__three">
          <div className="home__section__three__start">
            <span>
              <FaQuoteLeft />
              <FaQuoteRight />
            </span>
            <p>
              Welcome to OXXO University, where your journey to entrepreneurial
              success and professional excellence begins. At OXXO University, we
              believe in the power of innovation, the spirit of
              entrepreneurship, and the limitless potential within each of our
              students. Our mission is to provide a transformative educational
              experience that equips you with the knowledge, skills, and
              confidence needed to navigate and thrive in today's business
              landscape.
            </p>
            {/* <p>
              As directors of OXXO University, we are deeply commited to
              fostering an enviroment that nurtures creativity, encourages
              critical thinking, and promotes a hands-on approach to learning.
              Our carefully curated programs are designed to address the
              real-world challenges faced by entrepreneurs, small business
              owners, and young professionals. Whether you launching a start-up,
              scaling an SME, or advancing your career, our comprehensive
              curriculum and expert faculty are here to guide and support you
              every stp of the way.
            </p> */}
            <p>
              We take pride in our diverse communiity of students and alumni who
              continously inspire us with their achievements and dedication. The
              success stories that emerge from OXXO University are a testament
              to the resilience, hard work, and innovative mindset that we
              strive to instill in our learners.
            </p>
            <p>
              Our vision extends beyond academic excellence. We aim to create a
              collaborative ecosystem where ideas are exchanged, partnerships
              are formed, and lifelong relationships are built. We encourage you
              to take full advantage of the networking opportunities, mentorship
              programs, and practical experiences available to you. Engage
              actively with your peers, seek guidance from our experienced
              faculty, and immerse yourself in the rich learning experience that
              OXXO University offers.
            </p>
            <p>
              As you embark on this exciting journey with us, we want you to
              remember that your potential is limitless. At OXXO Learning, you
              will find the resources, support, and inspiration needed to turn
              your aspirations into reality. We are excited to be part of your
              journey and look forward to celebrating your successes.
            </p>
            <p>
              Thank you for choosing OXXO University. Together, we will build a
              brighter future.
            </p>
            <div>
              <h3>CEO and co-founder of OXXO University</h3>
              <h2>Felix O. Uloko, Ph.D </h2>
            </div>
          </div>
          <div className="home__section__three__end"></div>
        </section>
        <section className="home__section__four">
          <h1>OXXO at a Glance</h1>
          <div className="home__section__four__wrap">
            <div
              className="home__section__four__block1"
              ref={homeBlock}
              onMouseOver={highlightHomeBlock}
            >
              <img src={homeFacultyImage1} alt="faculty image" />
              <h1>World-Class Faculty</h1>
              <p>
                At OXXO University, our faculty members are leaders in their
                fields, bringing a wealth of experience and expertise to the
                classroom. They are dedicated to providing a dynamic and
                engaging learning environment, where students are encouraged to
                think critically and creatively.
                <br />
                Our professors are not only educators but also mentors who
                support and inspire students to achieve their academic and
                professional goals.
              </p>
            </div>
            <div
              className="home__section__four__block2"
              ref={homeBlock2}
              onMouseOver={highlightHomeBlock2}
            >
              <img src={homeFacultyImage2} alt="faculty image" />
              <h1>Innovative Curriculum</h1>
              <p>
                Our innovative curriculum is designed to meet the demands of the
                modern business world. We offer a diverse range of courses that
                cover essential business disciplines, from finance and marketing
                to international business and entrepreneurship. Each course is
                structured to provide a balance of theoretical knowledge and
                practical application, ensuring that our graduates are
                well-prepared to excel in their careers. The curriculum is
                continually updated to reflect the latest industry trends and
                best practices.
              </p>
            </div>
            <div
              className="home__section__four__block3"
              ref={homeBlock3}
              onMouseOver={highlightHomeBlock3}
            >
              <img src={homeCampusImage3} alt="campus image" />
              <h1>Experience a career defining session</h1>
            </div>
          </div>
        </section>

        <section className="home__section__five">
          <div className="home__section__five__start">
            <div className="home__section__five__start__header">
              <h1>Mini MBA Courses offered at OXXO University</h1>
            </div>
            <div className="home__section__five__start__wrap">
              <p>
                At OXXO University, real-world leadership challenges come to
                life. Our programs and courses will help you learn how to think
                expansively, act decisively, and inspire those around you to
                achieve great things.
              </p>
              <h3 onClick={() => navigate("courses")}>
                Check complete courses <BsArrowRight />
              </h3>
            </div>
          </div>
          <div className="home__section__five__end">
            <div className="home__section__five__end__start">
              <span className="home__section__five__end__block">
                <HiMinus className="home__section__five__end__block__icon" />
                <div>
                  <h1>Business Management & Entrepreneurship </h1>
                  <p>
                    The MBA in Business Administration & Entrepreneurship at
                    Oxxo University is designed to equip students with essential
                    business management skills and entrepreneurial acumen. This
                    program focuses on creating, growing, and sustaining
                    successful business ventures in a competitive marketplace.
                  </p>
                  <h3>5 months (+1 month optional mentorship)</h3>
                </div>
              </span>
              <span className="home__section__five__end__block">
                <HiMinus className="home__section__five__end__block__icon" />
                <div>
                  <h1>Supply Chain Management </h1>
                  <p>
                    The MBA in Supply Chain Management focuses on optimizing the
                    flow of goods and services, from raw materials to the end
                    consumer. This program covers all aspects of supply chain
                    operations, logistics, and strategic sourcing.
                  </p>
                  <h3>5 months (+1 month optional mentorship)</h3>
                </div>
              </span>
              <span className="home__section__five__end__block">
                <HiMinus className="home__section__five__end__block__icon" />
                <div>
                  <h1>Retail Management </h1>
                  <p>
                    The MBA in Retail Management prepares students for
                    leadership roles in the retail industry. The program covers
                    key areas such as merchandising, customer experience, and
                    retail operations.
                  </p>
                  <h3>5 months (+1 month optional mentorship)</h3>
                </div>
              </span>
              <span className="home__section__five__end__block">
                <HiMinus className="home__section__five__end__block__icon" />
                <div>
                  <h1>Corporate Finance </h1>
                  <p>
                    The MBA in Corporate Finance is designed for individuals
                    aiming to excel in financial management roles within
                    corporations. The program covers financial analysis,
                    investment strategies, and corporate financial planning.
                  </p>
                  <h3>5 months (+1 month optional mentorship)</h3>
                </div>
              </span>
              <span className="home__section__five__end__block">
                <HiMinus className="home__section__five__end__block__icon" />
                <div>
                  <h1>Entrepreneurship and Leadership </h1>
                  <p>
                    The MBA in Entrepreneurship and Leadership focuses on
                    developing leadership skills and entrepreneurial
                    capabilities. The program is designed for aspiring leaders
                    who want to drive innovation and growth within their
                    organizations or start their own ventures.
                  </p>
                  <h3>5 months (+1 month optional mentorship)</h3>
                </div>
              </span>
              <span className="home__section__five__end__block">
                <HiMinus className="home__section__five__end__block__icon" />
                <div>
                  <h1>Sales and Marketing </h1>
                  <p>
                    The MBA in Sales and Marketing equips students with advanced
                    skills in sales strategies and marketing techniques. This
                    program is ideal for those looking to lead marketing
                    campaigns, drive sales growth, and understand consumer
                    behavior.
                  </p>
                  <h3>5 months (+1 month optional mentorship)</h3>
                </div>
              </span>
            </div>
            <img
              src={studentImage}
              alt="student image"
              className="home__section__five__end__img"
            />
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
}

export default Home;
