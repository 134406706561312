import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./courses.css";
import image1 from "../../assets/emmanuel-ikwuegbu-h5xEHzfepNk-unsplash.jpg";
import { GoDotFill } from "react-icons/go";

function Courses() {
  return (
    <>
      <Header />
      <div className="courses">
        <section className="courses__section__one">
          <h3>mini MBA / Courses</h3>
          <h1>Our integrated courses</h1>
        </section>
        <section className="courses__section__two">
          <h3>
            REACH BEYOND THE BOUNDARIES. UNDERSTAND HOW REAL ORGANIZATIONS WORK.
          </h3>
          <p>
            As a leading business school, OXXO University offers a meticulously
            curated selection of eight courses designed to equip you with the
            essential skills and knowledge required to thrive in the fast-paced
            world of business. Each course is crafted by our expert faculty, who
            bring a wealth of real-world experience and academic excellence to
            the classroom.
          </p>
          <p>
            Whether you are looking to delve into the intricacies of financial
            management, explore the dynamics of international business, or
            master the art of marketing, our courses provide a comprehensive and
            practical approach to learning.
          </p>
          <img src={image1} alt="" />
        </section>
        <section className="courses__section__three">
          <p>
            At OXXO University, we understand that the business landscape is
            constantly evolving, and our courses are continually updated to
            reflect the latest industry trends and innovations. Our programs
            emphasize a balance of theoretical foundations and hands-on
            applications, ensuring that you graduate with not only a deep
            understanding of business principles but also the practical
            experience to apply them effectively. Join us at OXXO University and
            embark on a transformative educational journey that will empower you
            to become a leader in your chosen field. Discover our diverse range
            of courses and find the perfect fit for your academic and career
            aspirations.
          </p>
          <div className="courses__section__three__block">
            <h1> Business Management & Entrepreneurship</h1>
            <h2>Duration:5 months (+1 month optional mentorship)</h2>
            <h3>
              Program Overview: The MBA in Business Administration &
              Entrepreneurship at Oxxo University is designed to equip students
              with essential business management skills and entrepreneurial
              acumen. This program focuses on creating, growing, and sustaining
              successful business ventures in a competitive marketplace
            </h3>
            <div className="courses__section__three__block__list1">
              <h3>Key Learning Objectives:</h3>
              <ul>
                <li>
                  <GoDotFill /> Understand the core principles of business
                  administration.
                </li>
                <li>
                  <GoDotFill /> Develop strategic thinking and planning skills.
                </li>
                <li>
                  <GoDotFill /> Learn how to identify and evaluate business
                  opportunities.
                </li>
                <li>
                  <GoDotFill /> Gain insights into financing new ventures and
                  managing cash flow.
                </li>
                <li>
                  <GoDotFill /> Master the art of pitching ideas to investors.
                </li>
              </ul>
            </div>
            <div className="courses__section__three__block__list2">
              <h3>Core Modules:</h3>
              <ul>
                <li>
                  <GoDotFill /> Foundations of Business Administration:
                  Introduction to business operations, organizational
                  structures, and management principles.
                </li>
                <li>
                  <GoDotFill /> Entrepreneurial Mindset: Cultivating creativity,
                  innovation, and resilience.
                </li>
                <li>
                  <GoDotFill /> Business Planning and Strategy: Crafting
                  comprehensive business plans and strategies for growth.
                </li>
                <li>
                  <GoDotFill /> Financial Management for Entrepreneurs:
                  Budgeting, financial forecasting, and funding strategies.
                </li>
                <li>
                  <GoDotFill /> Marketing for Startups: Leveraging digital and
                  traditional marketing techniques to build brand presence.
                </li>
              </ul>
            </div>
            <h4>
              Optional Mentorship Program: A one-month mentorship with seasoned
              entrepreneurs who will provide guidance, feedback, and real-world
              insights to help students refine their business ideas and
              strategies.
            </h4>
          </div>
          <div className="courses__section__three__block">
            <h1> Supply Chain Management</h1>
            <h2>Duration:5 months (+1 month optional mentorship)</h2>
            <h3>
              Program Overview: The MBA in Supply Chain Management focuses on
              optimizing the flow of goods and services, from raw materials to
              the end consumer. This program covers all aspects of supply chain
              operations, logistics, and strategic sourcing.
            </h3>
            <div className="courses__section__three__block__list1">
              <h3>Key Learning Objectives:</h3>
              <ul>
                <li>
                  <GoDotFill /> Master the end-to-end supply chain processes.
                </li>
                <li>
                  <GoDotFill /> Enhance operational efficiency and productivity.
                </li>
                <li>
                  <GoDotFill /> Understand global supply chain dynamics and
                  risks.
                </li>
                <li>
                  <GoDotFill /> Develop skills in procurement, inventory
                  management, and logistics.
                </li>
                <li>
                  <GoDotFill /> Utilize technology and data analytics in supply
                  chain decision-making.
                </li>
              </ul>
            </div>
            <div className="courses__section__three__block__list2">
              <h3>Core Modules:</h3>
              <ul>
                <li>
                  <GoDotFill /> Introduction to Supply Chain Management: Basics
                  of supply chain operations and logistics.
                </li>
                <li>
                  <GoDotFill /> Strategic Sourcing and Procurement: Effective
                  sourcing strategies and supplier relationship management.
                </li>
                <li>
                  <GoDotFill /> Logistics and Transportation Management:
                  Optimizing transportation and distribution networks.
                </li>
                <li>
                  <GoDotFill /> Inventory and Warehouse Management: Techniques
                  for inventory control and warehouse optimization.
                </li>
                <li>
                  <GoDotFill /> Supply Chain Analytics: Leveraging data for
                  better supply chain decisions.
                </li>
              </ul>
            </div>
            <h4>
              Optional Mentorship Program: A one-month mentorship with supply
              chain experts who will provide practical insights and help
              students tackle real-world supply chain challenges.
            </h4>
          </div>
          <div className="courses__section__three__block">
            <h1>Retail Management</h1>
            <h2>Duration:5 months (+1 month optional mentorship)</h2>
            <h3>
              Program Overview: The MBA in Retail Management prepares students
              for leadership roles in the retail industry. The program covers
              key areas such as merchandising, customer experience, and retail
              operations.
            </h3>
            <div className="courses__section__three__block__list1">
              <h3>Key Learning Objectives:</h3>
              <ul>
                <li>
                  <GoDotFill /> Understand the dynamics of the retail industry.
                </li>
                <li>
                  <GoDotFill /> Develop effective retail strategies and store
                  management skills.
                </li>
                <li>
                  <GoDotFill /> Enhance customer experience through innovative
                  practices.
                </li>
                <li>
                  <GoDotFill /> Master inventory management and supply chain
                  coordination in retail.
                </li>
                <li>
                  <GoDotFill /> Utilize technology to drive retail success.
                </li>
              </ul>
            </div>
            <div className="courses__section__three__block__list2">
              <h3>Core Modules:</h3>
              <ul>
                <li>
                  <GoDotFill /> Retail Industry Overview: Understanding the
                  retail landscape and consumer behavior.
                </li>
                <li>
                  <GoDotFill /> DMerchandising and Category Management:
                  Techniques for effective product assortment and display.
                </li>
                <li>
                  <GoDotFill /> Retail Operations Management: Optimizing store
                  operations and workforce management.
                </li>
                <li>
                  <GoDotFill /> Customer Experience and Service Excellence:
                  Creating memorable customer experiences.
                </li>
                <li>
                  <GoDotFill /> E-commerce and Digital Retailing: Integrating
                  online and offline retail strategies.
                </li>
              </ul>
            </div>
            <h4>
              Optional Mentorship Program: A one-month mentorship with retail
              industry veterans who will share their expertise and help students
              implement their learning in real-world retail environments.
            </h4>
          </div>
          <div className="courses__section__three__block">
            <h1>Fashion Design, Trends, & Management</h1>
            <h2>Duration:5 months (+1 month optional mentorship)</h2>
            <h3>
              Program Overview: The MBA in Fashion Design, Trends & Management
              combines creative design with business management principles to
              prepare students for the dynamic fashion industry. The program
              emphasizes trend analysis, fashion marketing, and brand
              management.
            </h3>
            <div className="courses__section__three__block__list1">
              <h3>Key Learning Objectives:</h3>
              <ul>
                <li>
                  <GoDotFill /> Develop skills in fashion design and trend
                  forecasting.
                </li>
                <li>
                  <GoDotFill /> Understand the global fashion market and
                  consumer preferences.
                </li>
                <li>
                  <GoDotFill /> Learn fashion marketing and brand management
                  techniques.
                </li>
                <li>
                  <GoDotFill /> Gain insights into sustainable fashion
                  practices.
                </li>
                <li>
                  <GoDotFill /> Master the business aspects of running a fashion
                  enterprise.
                </li>
              </ul>
            </div>
            <div className="courses__section__three__block__list2">
              <h3>Core Modules:</h3>
              <ul>
                <li>
                  <GoDotFill /> Fashion Design Principles: Fundamentals of
                  fashion design and garment construction.
                </li>
                <li>
                  <GoDotFill /> Trend Analysis and Forecasting: Identifying and
                  predicting fashion trends.
                </li>
                <li>
                  <GoDotFill /> Fashion Marketing and Branding: Strategies for
                  promoting fashion brands.
                </li>
                <li>
                  <GoDotFill /> Fashion Retail and Merchandising: Managing
                  fashion retail operations and visual merchandising.
                </li>
                <li>
                  <GoDotFill />
                  Sustainable Fashion: Implementing sustainable practices in
                  fashion design and production.
                </li>
              </ul>
            </div>
            <h4>
              Optional Mentorship Program: A one-month mentorship with fashion
              industry professionals who will guide students through their
              design projects and provide insights into the fashion business.
            </h4>
          </div>
          <div className="courses__section__three__block">
            <h1>Corporate Finance</h1>
            <h2>Duration:5 months (+1 month optional mentorship)</h2>
            <h3>
              Program Overview: The MBA in Corporate Finance is designed for
              individuals aiming to excel in financial management roles within
              corporations. The program covers financial analysis, investment
              strategies, and corporate financial planning.
            </h3>
            <div className="courses__section__three__block__list1">
              <h3>Key Learning Objectives:</h3>
              <ul>
                <li>
                  <GoDotFill /> Master financial statement analysis and
                  corporate finance principles.
                </li>
                <li>
                  <GoDotFill /> Develop skills in financial planning and
                  analysis (FP&A).
                </li>
                <li>
                  <GoDotFill /> Understand investment strategies and capital
                  markets.
                </li>
                <li>
                  <GoDotFill /> Learn risk management and corporate governance
                  practices.
                </li>
                <li>
                  <GoDotFill />
                  Utilize financial modeling and data analysis tools.
                </li>
              </ul>
            </div>
            <div className="courses__section__three__block__list2">
              <h3>Core Modules:</h3>
              <ul>
                <li>
                  <GoDotFill /> Financial Accounting and Reporting: Principles
                  of financial accounting and reporting standards.
                </li>
                <li>
                  <GoDotFill /> Corporate Financial Management: Financial
                  planning, capital budgeting, and working capital management.
                </li>
                <li>
                  <GoDotFill /> Investment Analysis and Portfolio Management:
                  Evaluating investment opportunities and managing portfolios.
                </li>
                <li>
                  <GoDotFill /> Risk Management and Corporate Governance:
                  Identifying and mitigating financial risks.
                </li>
                <li>
                  <GoDotFill /> Financial Modeling and Data Analysis: Building
                  financial models and using data for decision-making.
                </li>
              </ul>
            </div>
            <h4>
              Optional Mentorship Program: A one-month mentorship with
              experienced finance professionals who will provide practical
              advice and help students apply their knowledge to real corporate
              finance scenarios.
            </h4>
          </div>
          <div className="courses__section__three__block">
            <h1> Entrepreneurship & Leadership</h1>
            <h2>Duration:5 months (+1 month optional mentorship)</h2>
            <h3>
              Program Overview: The MBA in Entrepreneurship and Leadership
              focuses on developing leadership skills and entrepreneurial
              capabilities. The program is designed for aspiring leaders who
              want to drive innovation and growth within their organizations or
              start their own ventures.
            </h3>
            <div className="courses__section__three__block__list1">
              <h3>Key Learning Objectives:</h3>
              <ul>
                <li>
                  <GoDotFill />
                  Cultivate leadership qualities and interpersonal skills.
                </li>
                <li>
                  <GoDotFill /> Develop strategic thinking and decision-making
                  abilities.
                </li>
                <li>
                  <GoDotFill /> Learn how to build and lead high-performing
                  teams.
                </li>
                <li>
                  <GoDotFill /> Understand the process of launching and scaling
                  a business.
                </li>
                <li>
                  <GoDotFill /> Gain insights into ethical leadership and
                  corporate social responsibility.
                </li>
              </ul>
            </div>
            <div className="courses__section__three__block__list2">
              <h3>Core Modules:</h3>
              <ul>
                <li>
                  <GoDotFill /> Leadership Fundamentals: Theories and practices
                  of effective leadership.
                </li>
                <li>
                  <GoDotFill /> Strategic Management: Formulating and
                  implementing business strategies.
                </li>
                <li>
                  <GoDotFill />
                  Team Building and Human Resource Management: Leading and
                  managing teams for optimal performance.
                </li>
                <li>
                  <GoDotFill /> Entrepreneurial Leadership: Driving innovation
                  and managing entrepreneurial ventures.
                </li>
                <li>
                  <GoDotFill /> Ethics and Corporate Social Responsibility:
                  Promoting ethical practices and social responsibility in
                  business.
                </li>
              </ul>
            </div>
            <h4>
              Optional Mentorship Program: A one-month mentorship with
              successful entrepreneurs and business leaders who will provide
              guidance on leadership challenges and entrepreneurial ventures.
            </h4>
          </div>
          <div className="courses__section__three__block">
            <h1>Sales and Marketing</h1>
            <h2>Duration:5 months (+1 month optional mentorship)</h2>
            <h3>
              Program Overview: The MBA in Sales and Marketing equips students
              with advanced skills in sales strategies and marketing techniques.
              This program is ideal for those looking to lead marketing
              campaigns, drive sales growth, and understand consumer behavior.
            </h3>
            <div className="courses__section__three__block__list1">
              <h3>Key Learning Objectives:</h3>
              <ul>
                <li>
                  <GoDotFill />
                  Develop effective sales strategies and techniques.
                </li>
                <li>
                  <GoDotFill /> Understand consumer behavior and market
                  segmentation.
                </li>
                <li>
                  <GoDotFill /> Learn integrated marketing communications and
                  digital marketing.
                </li>
                <li>
                  <GoDotFill /> Gain skills in brand management and product
                  positioning.
                </li>
                <li>
                  <GoDotFill /> Analyze market data to drive strategic
                  decisions.
                </li>
              </ul>
            </div>
            <div className="courses__section__three__block__list2">
              <h3>Core Modules:</h3>
              <ul>
                <li>
                  <GoDotFill /> Sales Management: Strategies for managing and
                  optimizing sales teams.
                </li>
                <li>
                  <GoDotFill /> Consumer Behavior: Understanding the psychology
                  of consumers and purchasing decisions.
                </li>
                <li>
                  <GoDotFill /> Marketing Communications: Crafting and executing
                  marketing campaigns.
                </li>
                <li>
                  <GoDotFill /> Digital Marketing: Utilizing online platforms
                  and tools for marketing success.
                </li>
                <li>
                  <GoDotFill /> Brand Management and Product Strategy: Building
                  and managing strong brands and product lines.
                </li>
              </ul>
            </div>
            <h4>
              Optional Mentorship Program: A one-month mentorship with seasoned
              sales and marketing professionals who will help students refine
              their strategies and apply best practices in real-world scenarios.
            </h4>
          </div>
          <div className="courses__section__three__block">
            <h1> Digital Technology</h1>
            <h2>Duration:5 months (+1 month optional mentorship)</h2>
            <h3>
              Program Overview: The MBA in Digital Technology is designed to
              prepare students for leadership roles in the tech industry. The
              program covers essential topics such as digital transformation,
              emerging technologies, and tech-driven business strategies.
            </h3>
            <div className="courses__section__three__block__list1">
              <h3>Key Learning Objectives:</h3>
              <ul>
                <li>
                  <GoDotFill /> Understand the impact of digital transformation
                  on businesses.
                </li>
                <li>
                  <GoDotFill /> Develop strategies for leveraging emerging
                  technologies.
                </li>
                <li>
                  <GoDotFill /> Gain insights into cybersecurity, data
                  analytics, and cloud computing.
                </li>
                <li>
                  <GoDotFill /> Learn how to manage tech projects and lead tech
                  teams.
                </li>
                <li>
                  <GoDotFill /> Explore the ethical and regulatory aspects of
                  digital technology.
                </li>
              </ul>
            </div>
            <div className="courses__section__three__block__list2">
              <h3>Core Modules:</h3>
              <ul>
                <li>
                  <GoDotFill /> Digital Transformation: Strategies for driving
                  digital change within organizations.
                </li>
                <li>
                  <GoDotFill /> Emerging Technologies: Understanding and
                  applying new technologies such as AI, IoT, and blockchain.
                </li>
                <li>
                  <GoDotFill /> Cybersecurity Management: Protecting digital
                  assets and managing cybersecurity risks.
                </li>
                <li>
                  <GoDotFill /> Data Analytics and Business Intelligence:
                  Leveraging data for strategic decision-making.
                </li>
                <li>
                  <GoDotFill /> Tech Project Management: Planning, executing,
                  and managing technology projects.
                </li>
              </ul>
            </div>
            <h4>
              Optional Mentorship Program: A one-month mentorship with
              technology leaders who will provide insights into the tech
              industry and help students apply their knowledge to real-world
              digital technology challenges.
            </h4>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Courses;
