import { useRef, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./apply.css";
import CountdownTimer from "../../utils/Countdown";
import brochure from "../../assets/OXXO_UNIVERSITY_BROCHURE.pdf";
import Modal from "../../components/modal/Modal";
import logo from "../../assets/OXXO LOGO PNG.png";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
import Toaster from "../../utils/toaster/Toaster";
import emailjs, { init } from "@emailjs/browser";

function Apply() {
  const targetDate = "2024-11-03T00:00:00"; // Set your target date here
  const todayDate = new Date();

  const [isOpen, setIsOpen] = useState();
  const [toaster, setToaster] = useState(false);
  const [message, setMessage] = useState("");
  const [bgcolor, setbgcolor] = useState("");
  const [incompleteFields, setIncompleteFields] = useState(false);
  const form = useRef();
  const [imageUrl, setImageUrl] = useState("");

  init("fL4UraYJiFZYYrz6z");

  const [formStatus, setFormStatus] = useState({
    submitting: false,
    submitted: false,
  });

  const [fileName, setFileName] = useState("No file selected");

  const uploadImage = async (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    const url = `https://api.cloudinary.com/v1_1/du1dvxjo8/image/upload`;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "backendgps");

    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    setImageUrl(data.secure_url);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    // city: "",
    address: "",
    state: "",
    email: "",
    address: "",
    education: "",
    program: "",
    id: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIncompleteFields(false);

    // Validate form inputs
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.phone ||
      !formData.address ||
      !formData.state ||
      !formData.education ||
      !formData.program ||
      !formData.id
    ) {
      setIncompleteFields(true);
      return;
    }

    try {
      setFormStatus({ ...formStatus, submitting: true });

      //handle form submission logic here
      setFormStatus({ submitting: false, submitted: true });
      setFormData({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        phone: "",
        address: "",
        state: "",
        education: "",
        program: "",
        id: "",
      });
      setImageUrl("");
      setFileName("No file selected");

      console.log(formData);
      console.log(imageUrl);

      //Email JS send form logic
      const data = formData;

      data.documentUrl = imageUrl;

      emailjs
        .send("service_gv8dt8j", "template_em9lpet", data, "fL4UraYJiFZYYrz6z")
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );

      setTimeout(() => {
        setFormStatus({ ...formStatus, submitted: false });
      }, 10000);
    } catch (error) {
      console.error("Error sending email:", error);
      setFormStatus({ submitting: false, submitted: false });
      // Handle error scenario
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      {toaster && <Toaster message={message} bgcolor={bgcolor} />}
      <Header />
      <div className="apply">
        <section className="apply__section__one">
          <div className="apply__section__one__start">
            <h1>A year of growth.</h1>
            <h1>A lifetime of learning.</h1>
            <p>
              Welcome to the gateway of your future success! At OXXO University,
              our renowned business school is dedicated to cultivating the next
              generation of leaders, innovators, and entrepreneurs. By choosing
              to apply to OXXO, you are taking the first step toward an
              education that combines cutting-edge business knowledge with
              real-world experience.
              <br />
              <br />
              Our dynamic and supportive learning environment, distinguished
              faculty, and global network of alumni will equip you with the
              skills and connections needed to excel in today's competitive
              business landscape. Begin your journey with OXXO University today.
              Explore our application process, learn about our diverse programs,
              and discover how you can become part of our vibrant community.
              Your future in business starts here.
            </p>
          </div>
          <div className="apply__section__one__end">
            <button
              className="apply__section__one__end__button__apply"
              onClick={() => {
                if (todayDate >= new Date(targetDate)) {
                  setToaster(true);
                  setbgcolor("salmon");
                  setMessage(
                    "Application deadline exceeded! You can no longer apply for the upcoming cohort."
                  );
                  setTimeout(() => {
                    setToaster(false);
                  }, 10100);
                  return;
                }

                openModal();
              }}
            >
              Apply for November cohort
            </button>
            <button className="apply__section__one__end__button__brochures">
              <a
                href={brochure}
                download
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Download Brochure
              </a>
            </button>
          </div>
        </section>
        <section className="apply__section__two">
          <h1>Application process</h1>
          <div className="apply__section__two__wrap">
            <div className="apply__section__two__block">
              <h1>01</h1>
              <p>
                Submit Application: Complete and submit the application form
                along with the required documents (business registration,
                personal statement, and identification).
              </p>
            </div>
            <div className="apply__section__two__block">
              <h1>02</h1>
              <p>
                Review Process: The admissions committee will review the
                application materials to access the applicant's suitability for
                the progream.
              </p>
            </div>
            <div className="apply__section__two__block">
              <h1>03</h1>
              <p>
                Interview (if necessary): Some applicants may be invited for an
                interview to further discuss their business experience and
                goals.
              </p>
            </div>
            <div className="apply__section__two__block">
              <h1>04</h1>
              <p>
                Admission Decision: Applicants will be notified of the
                submission decison via emaili. Succesful candidats will receive
                details about the next steps, including cours enrollment and
                orientation.
              </p>
            </div>
          </div>
        </section>
        <section className="apply__section__three">
          <h1>Application deadline</h1>
          <CountdownTimer targetDate={targetDate} />
        </section>
      </div>
      <Footer />

      {/* APPLICATION MODAL */}
      <Modal isOpen={isOpen} onClose={closeModal}>
        <form onSubmit={handleSubmit} className="modal__apply" ref={form}>
          <img
            src={logo}
            alt="OXXO University Logo"
            className="modal__apply__logo"
          />

          <div className="modal__apply__content">
            <h1>Apply for 2024/2025 Session</h1>
            <p>
              Please submit your application form, required documents, and
              personal statement by {new Date(targetDate).toLocaleDateString()}
              (deadline).
            </p>
            <div className="modal__apply__content__input__wrap">
              <span>
                <label className="modal__apply__label">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  disabled={formStatus.submitting}
                />
              </span>
              <span>
                <label className="modal__apply__label">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  disabled={formStatus.submitting}
                />
              </span>
              <span>
                <label className="modal__apply__label">Phone Number</label>
                <input
                  type="number"
                  name="phone"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                  disabled={formStatus.submitting}
                />
              </span>
              <span>
                <label className="modal__apply__label">Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={formStatus.submitting}
                />
              </span>
              <span>
                <label className="modal__apply__label">State</label>
                <input
                  type="text"
                  name="state"
                  placeholder="State"
                  value={formData.state}
                  onChange={handleChange}
                  disabled={formStatus.submitting}
                />
              </span>
              <span>
                <label className="modal__apply__label">Address</label>
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                  disabled={formStatus.submitting}
                />
              </span>
              <span>
                <h3 className="modal__apply__label">Means of ID</h3>
                <select
                  required
                  name="id"
                  id="id"
                  value={formData.id}
                  onChange={handleChange}
                  className="modal__apply__input"
                >
                  <option value={null}>--</option>
                  <option value="NIN">National Identification Number</option>
                  <option value="Voter's card">Voter's Card</option>
                  <option value="International Passport">
                    International Passport
                  </option>
                  <option value="Driver's lincense">Driver's lincense</option>
                  <option value="others">others</option>
                </select>
              </span>
              <span>
                <h3 className="modal__apply__label">Choice of Program</h3>
                <select
                  required
                  name="program"
                  id="program"
                  value={formData.program}
                  onChange={handleChange}
                  className="modal__apply__input"
                >
                  <option value={null}>--</option>
                  <option value="Business Management and Entrepreneurshhip">
                    Business Management and Entrepreneurshhip
                  </option>
                  <option value="Supply Chain Management">
                    Supply Chain Management
                  </option>
                  <option value="Retail Management">Retail Management</option>
                  <option value="Fashion Design, Trends and Management">
                    Fashion Design, Trends and Management
                  </option>
                  <option value="Corperate Finance">Corperate Finance</option>
                  <option value="Entrepreneurship and Leadership">
                    Entrepreneurship and Leadership
                  </option>
                  <option value="Sales and Marketing">
                    Sales and Marketing
                  </option>
                  <option value="Digital Technology">Digital Technology</option>
                </select>
              </span>{" "}
              <span>
                <h3 className="modal__apply__label">Education background</h3>
                <select
                  required
                  name="education"
                  id="education"
                  value={formData.education}
                  onChange={handleChange}
                  className="modal__apply__input"
                >
                  <option value={null}>--</option>
                  <option value="SSCE">SSCE</option>
                  <option value="Diploma">Diploma</option>
                  <option value="Bachelor's Degree">Bachelor's degree</option>
                  <option value="Master's Degree">Master's Degree</option>
                  <option value="Doctorate">Doctorate</option>
                  <option value="None">None</option>
                </select>
              </span>
              <span>
                <h3 className="modal__apply__label">Upload Document</h3>
                <form
                  className="image__upload"
                  onClick={() =>
                    document.querySelector(".input__field").click()
                  }
                >
                  <input
                    name="documentUrl"
                    id="documentUrl"
                    type="file"
                    accept="image/*"
                    className="input__field"
                    multiple
                    hidden
                    onChange={uploadImage}
                  />
                  {imageUrl ? (
                    <img src={imageUrl} width={75} height={75} alt={`ID`} />
                  ) : (
                    <MdCloudUpload color="#1475cf" size={50} />
                  )}
                </form>
                <div className="image__upload__label">
                  <AiFillFileImage color="#1475" />
                  <span>
                    {fileName}
                    <MdDelete
                      onClick={() => {
                        setFileName("No file selected");
                        setImageUrl(null);
                      }}
                    />
                  </span>
                </div>
              </span>
            </div>
          </div>
          {formStatus.submitted && (
            <div className="modal__apply__message">
              Application successful! You willl receive a follow up email within
              48 hours.
            </div>
          )}
          {incompleteFields && (
            <div className="modal__apply__message incomplete">
              Kindly fill all the fields in the application form to submit
            </div>
          )}

          <span className="modal__apply__buttons">
            <button
              type="submit"
              className="modal__apply__button"
              disabled={formStatus.submitting}
            >
              {formStatus.submitting ? "Submitting..." : " SUBMIT APPLICATION"}
            </button>
            <button className="modal__apply__close" onClick={closeModal}>
              Close
            </button>
          </span>
        </form>
      </Modal>
    </>
  );
}

export default Apply;
